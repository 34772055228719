<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div class="w-100">
          <div class="text-center">
            <img
              src="../../../assets/images/qii/select-house.png"
              style="width: 300px"
              alt=""
            />
          </div>
          <div
            v-if="
              user.totalPersons == 1 || (user.tenant && user.tenant.nordigen_account_id)
            "
          >
            <h1>{{ $t("start_responding_to_listings") }}</h1>
            <p class="fw-medium text-gray">
              {{ $t("qii_step5_detail") }}
            </p>
            <div class="w-100 mt-4">
              <button class="btn btn-primary btn-block" @click="$router.push('/')">
                {{ $t("view_all_lsitings") }}
              </button>
            </div>
          </div>
          <div
            v-if="
              user.totalPersons == 2 &&
              (!user.tenant || !user.tenant.veriff_id || !user.tenant.nordigen_account_id)
            "
          >
            <h1>{{ $t("add_second_main_tenant") }}</h1>
            <p class="fw-medium text-gray">
              {{ $t("qii_step5_second_tenant_detail") }}
            </p>
            <div class="w-100 mt-4">
              <button
                class="btn btn-primary btn-block"
                @click="$router.push('/user/flow/4/tenant')"
              >
                {{ $t("start_verification_second_tenant") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <div class="text-center">
          <img
            src="../../../assets/images/auth/register.svg"
            style="width: 300px"
            alt=""
          />
        </div>
        <h1>{{ $t("review_the_collected_data") }}</h1>
        <p class="fw-medium text-gray">
          {{ $t("qii_step5_second_tenant_detail") }}
        </p>
        <div class="w-100 mt-4">
          <button
            class="btn btn-outline-primary btn-block mt-3"
            @click="$router.push('/user/profile')"
          >
            {{ $t("check_my_profile") }}
          </button>
        </div>
      </div>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapGetters } from "vuex";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";

export default {
  data() {
    return {};
  },
  components: {
    AuthLayoutContent,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    max-width: 412px;

    @include xs {
      width: 93%;
    }
  }
}

.transactions {
  max-height: 350px;
  overflow-y: scroll;
  padding: 10px;
}
</style>
